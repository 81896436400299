<template>
  <div>
    <van-popup
      class="vantPop"
      v-model:show="showpopup"
      closeable
      :close-on-click-overlay="false"
      close-icon-position="top-right"
    >
      <div class="popup">
        <div class="content">
          <div class="title">恭喜获得</div>
          <div class="desc">
            <div class="citem" v-for="(item, index) in list" :key="index">
              <div class="flex">
                <img :src="item.image_icon" alt="" />
                <div class="">
                  {{ item.title }} x
                  <span>{{ item.reward_type == 6 ? item.reward_num / 100 : item.reward_num }}</span>
                </div>
              </div>
              <div
                class="btn"
                v-if="item.reward_type != 2 && item.reward_type != 5"
                @click="btnFn(item)"
              >
                <span v-if="item.reward_type == 1">查看</span>
                <span v-else-if="item.reward_type == 6">去领取</span>
                <span v-else-if="item.reward_type == 3">查看</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="btn">
          <div>知道了</div>
        </div> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { getorderresult } from '@/service/order';
import { useRouter } from 'vue-router';
export default {
  props: {
    order: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const router = useRouter();
    const state = reactive({
      showpopup: false,
      list: []
    });
    onMounted(async () => {
      console.log(111);
      const { data } = await getorderresult({ trade_no: props.order });

      state.list = data;
      state.showpopup = true;
    });

    const btnFn = item => {
      if (item.reward_type == 1 || item.reward_type == 3) {
        router.push({ path: '/collections' });
      } else if (item.reward_type == 6) {
        router.push({ path: '/myHb' });
      }
    };

    return {
      ...toRefs(state),
      btnFn
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.citem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.flex {
  display: flex;
  align-items: center;
  img {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }
  // font-size: 0px;
}
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  // text-align: center;
  color: #ffffff;
  font-size: 16px;

  .content {
    padding: 16px 20px 20px 20px;

    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
    .desc {
      font-weight: 400;
      margin-top: 20px;
      font-size: 12px;
      // span {
      //   color: $primary;
      // }
    }
  }
  .btn {
    width: 56px;
    height: 28px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 1px solid rgba(255, 255, 255, 0.7);
  }
}
</style>
