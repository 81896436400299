<template>
  <van-popup v-model:show="show" position="bottom">
    <div class="content">
      <div class="header">
        <img src="../imgs/comm/pic_tc_zs.png" class="img-zs" />
        <van-icon :name="icon_tc_gb" size="0.427rem" class="icon_tc_gb" @click="close" />

        <span class="type"> 选择特权</span>
      </div>
      <div class="coupons-box">
        <div
          class="cp-item"
          :class="active == item ? 'active' : ''"
          v-for="(item, index) in couponsList"
          :key="index"
          @click="active = item"
        >
          <div class="cp-money"><span>¥</span><span class="num">49.9</span></div>
          <div class="cp-right">
            <p class="t-c">预付款<span class="yellow">自动抢购</span>特权</p>
          </div>
        </div>
      </div>
      <div class="btn" @click="sureChoose">确 定</div>
    </div>
  </van-popup>
</template>

<script>
import { reactive, toRefs, watch, onMounted } from 'vue';
export default {
  emits: ['close'],
  setup(props, ctx) {
    const state = reactive({
      show: false,
      couponsList: [1, 2, 3, 4, 5, 6],
      active: null
    });
    const close = () => {
      // state.show = false;
      ctx.emit('close');
    };
    // 确认选择
    const sureChoose = () => {
      ctx.emit('sure', state.active);
      close();
    };
    return {
      ...toRefs(state),
      icon_tc_gb: require('../imgs/icon/icon_tc_gb2.png'),
      close,
      sureChoose
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.btn {
  width: 335px;
  height: 44px;
  background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
  border-radius: 4px 4px 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #1c172a;
  margin: 0px auto;
}
.t-c {
  text-align: center;
}
.coupons-box {
  padding: 0px 17px;
  max-height: 300px;
  margin-top: 18px;
  overflow: auto;
  .active {
    background-image: url('../imgs/comm/Slice37active.png') !important;
  }
  .cp-item {
    width: 100%;
    height: 75px;
    background-image: url('../imgs/comm/Slice37.png');
    background-size: 100%;
    color: $fc;
    display: flex;
    margin-bottom: 16px;
    align-items: center;

    .cp-money {
      text-align: center;
      width: 35%;
      color: $primary;
      font-size: 16px;
    }
    .num {
      font-size: 24px;
      font-weight: bold;
    }
    .cp-right {
      font-size: 14px;
      text-align: center;
      width: 75%;
    }
    .yellow {
      color: #fff0a3;
    }
  }
}
.content {
  background-color: $bc;
  padding-bottom: 60px;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .img-zs {
    height: 16px;
  }
  .icon_tc_gb {
    margin-left: -300px;
    width: 16px;
    height: 16px;
  }
  .img-title {
    height: 16px;
  }
  .type {
    // line-height: 16px;
    margin-top: 3px;
  }
  span {
    font-size: 20px;
    color: $fc;
    margin-top: -20px;
  }
}
</style>
