<template>
  <van-action-sheet :round="false" style="background-color: #1c172a">
    <div class="header">
      <img src="../imgs/comm/pic_tc_zs.png" class="img-zs" />
      <van-icon :name="icon_tc_gb" size="0.427rem" class="icon_tc_gb" @click="close" />
      <span class="type"> 确认订单</span>
    </div>

    <div class="content">
      <div class="block"></div>
      <div class="">
        <div class="title">
          <div class="title-left">
            <img :src="box.image_icon" alt="" />
            <div class="">
              <div class="display_name">{{ box.display_name }}</div>
              <div class="pay_title">{{ box.pay_title }}</div>
            </div>
          </div>
          <div class="title-right">
            <span class="icon">¥</span> <span>{{ box.pay_price / 100 }}</span
            >/份
          </div>
        </div>
        <div class="" v-if="!isOrder">
          <div class="num-box" v-if="box.is_draw_lots !== 1">
            <div class="num-left">
              <div
                class="choosenum"
                :class="addnum == item ? 'active' : ''"
                v-for="(item, index) in numbox"
                :key="index"
                @click="addnum = isOrder ? addnum : item"
              >
                {{ item }}份
              </div>
            </div>
            <div class="addnum">
              <van-stepper
                @change="piontComputed"
                :max="box.max_buy_num"
                @overlimit="stepChange"
                :disabled="isOrder"
                v-model="addnum"
                class="stepper"
              />
              <div class="maxnum">本单限购{{ box.max_buy_num }}份</div>
            </div>
          </div>
        </div>
        <!-- <div class="can_buy_num" v-if="isLogin && !isOrder">
          <div class="">
            提示：当前你最多可购买<span>{{ box.can_buy_num }}</span
            >份
          </div>
          <div class="getmore" @click="toinvite">获得更多购买份数<van-icon name="arrow" /></div>
        </div> -->
        <div class="pay-box" :style="box.is_draw_lots == 1 || isOrder ? 'margin-top:20px;' : ''">
          支付方式：
          <div class="" v-for="(item, index) in payArr.pay_ways" :key="index">
            <div
              class="payitem"
              :class="payType == item.pay_type ? 'active' : ''"
              @click="payType = item.pay_type"
            >
              <img :src="item.icon" alt="" />
              <div class="">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="point" v-if="box.discount_price != 0 && !isOrder">
          <div class="point-left">
            <div class="imgbox">
              <img :src="icon_point" />
            </div>
            <div class="words">
              <span v-if="myscores != 0">
                使用 <span class="col">{{ piontDeduction }}积分</span> 抵扣<span class="col"
                  >{{ piontDeduction / 100 }}元</span
                ></span
              >
              <span v-else>无积分可抵扣</span>
              <span class="ft">（最高可抵扣{{ box.discount_price / 100 }}元）</span>
            </div>
          </div>
          <div class="choose" v-if="myscores != 0" @click="cheacked = !cheacked">
            <van-icon :name="cheacked ? coll_choose : coll_no_choose" class="" />
          </div>
          <div class="arrow" @click="arrowClick" v-else>
            <van-icon name="arrow" />
          </div>
        </div>

        <div class="pay-bt">
          <div class="line"></div>
          <div class="pay-money">
            合计 <span class="span" v-if="cheacked">¥{{ (box.pay_price * addnum) / 100 }}</span>
            <span class="pay" v-if="cheacked">
              ¥{{ (box.pay_price * addnum - piontDeduction) / 100 }}</span
            >
            <span class="pay" v-else>
              ¥{{ isOrder ? box.pay_price / 100 : (box.pay_price * addnum) / 100 }}</span
            >
          </div>
          <div class="btn" @click="payMeth">去支付</div>
        </div>
      </div>
      <div class="block2"></div>
    </div>
  </van-action-sheet>
</template>

<script>
import { reactive, toRefs, watch, onMounted, nextTick } from 'vue';
import ChooseCoupons from './ChooseCoupons.vue';
import { localStorageUtil, nftUtils } from '@/utils';
import { useRouter } from 'vue-router';
import { pay, payways, payinadvance, alipay_wap_pay, sandpay_unionpayh5 } from '@/service/order';
import { Toast } from 'vant';

export default {
  emits: ['close'],
  components: {
    // ChooseCoupons
  },
  props: {
    box: {
      type: Object
    },
    payStatus: {
      type: Number,
      default: 1 ////1--正常支付，2--预付款
    },
    isOrder: {
      type: Boolean,
      default: false
    },
    batch_count: {
      type: Number,
      default: 1 // 份数
    }
  },
  setup(props, ctx) {
    const router = useRouter();
    const state = reactive({
      payType: 11,
      payArr: {},
      getCard: true,
      checked: false,
      showCoupons: false, //选择优惠券
      payStatus: props.payStatus,
      isLogin: false,
      orderId: '', //订单id
      numbox: props.box.buy_limits, // [5, 10, 20],
      addnum: props.batch_count,
      cheacked: false,
      piontDeduction: 0,
      box: props.box,
      myscores: 0 // 我的积分
    });
    watch(
      () => props.payStatus,
      payStatus => {
        state.payStatus = payStatus;
      }
    );
    watch(
      () => props.box,
      box => {
        state.box = box;
        state.numbox = box.buy_limits;
      }
    );
    onMounted(async () => {
      state.isLogin = nftUtils.isLogin();
      if (state.isLogin) {
        const { data } = await payways();

        if (data.pay_ways && data.pay_ways.length > 0) {
          const payItems = data.pay_ways.filter(item => {
            return item.pay_type == 11;
          });

          if (payItems && payItems.length > 0) {
            state.payType = payItems[0].pay_type;
          } else {
            state.payType = data.pay_ways[data.pay_ways.length - 1].pay_type;
          }
        }
        state.payArr = data;
        setTimeout(() => {
          piontComputed();
        }, 200);
      }
    });
    const close = () => {
      ctx.emit('close');
    };

    const stepChange = () => {
      if (state.addnum == 1) {
        Toast('每笔订单至少购买一份');
      }
      if (state.addnum == 3) {
        Toast('每笔订单最多可购买3份');
      }
    };

    const toinvite = () => {
      //  nftUtils.setcnzzRecordByBtn('邀请有奖');
      // router.push({ path: '/inviteActivity' });
      router.push({ path: '/invite/invite6' });
    };
    const payMeth = () => {
      // 存储定时标识符，以便清除定时器
      if (state.getCard) {
        toPay();
      }
    };
    //积分换算
    const piontComputed = () => {
      // 系统最高可抵扣积分
      let maxpoint = state.box.discount_price * state.addnum,
        myPoint = state.payArr.user_acct_info.scores;
      state.myscores = state.payArr.user_acct_info.scores;
      state.piontDeduction = myPoint >= maxpoint ? maxpoint : myPoint;
      // 判断自己的积分
      //  console.log(state.piontDeduction, '.....', maxpoint, state.box.discount_price);
    };
    // 确认优惠卷选择
    const sureCoup = active => {
      console.log(active);
    };
    // 获取优惠卷列表
    const getCardMeth = () => {
      state.showCoupons = true;
    };
    // 订单中心支付
    const orderPay = async () => {
      // 支付宝支付、杉德支付跳转后端返回的支付链接
      state.payStatus == 1 && localStorageUtil.set('orderId', state.box.trade_no);
      if ([18, 42].includes(props.box.collections_id)) {
        // 新人盲盒
        localStorageUtil.set('isNew', 1);
      }
      if (
        (state.payType == 11 && state.box.pay_type == 11) ||
        (state.payType == 25 && state.box.pay_type == 25)
      ) {
        window.location.href = JSON.parse(state.box.pay_content).url;
      } else if (state.payType == 24 && state.box.pay_type == 24) {
        // 通联支付跳转支付页面
        router.push({
          path: `/tonglianPay`,
          query: {
            order: state.box.trade_no,
            money: state.box.pay_price
          }
        });
      } else {
        //选择其他支付方式
        state.orderId = state.box.trade_no;
        justPay();
      }
    };
    const arrowClick = () => {
      router.push({
        path: `/myPoint`
      });
    };
    const toPay = async () => {
      //是否为订单中心支付
      if (props.isOrder) {
        orderPay();
        return;
      }
      // 判断是否登陆
      //   console.log(1111);

      if (localStorageUtil.get('accessToken')) {
        state.getCard = false;
        // 支付
        // let
        Toast.loading({
          message: '订单生成中',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        let postData = state.payStatus == 1 ? pay : payinadvance; //
        const res = await postData({
          collections_id: state.box.id, //: state.productDetail.collections_id,
          pay_type: state.payType,
          back_url: location.href, // `${location.origin}/boxopen`
          batch_count: state.addnum,
          score: state.cheacked ? state.piontDeduction : 0
        });

        if (res.status != 0) {
          Toast.clear();
          Toast(res.message);
          setTimeout(() => {
            state.getCard = true;
          }, 1500);
          return;
        }
        // 保存订单id
        state.orderId = res.data;
        justPay();
      } else {
        router.push({ path: `/login` });
      }
    };
    const justPay = async () => {
      if (state.payType == 11) {
        // 支付宝支付
        const { data, status, message } = await alipay_wap_pay({
          order_no: state.orderId
        });
        payCallBack(data, status, message);
      } else if (state.payType == 24) {
        // 通联支付
        Toast.clear();
        router.push({
          path: `/tonglianPay`,
          query: {
            order: state.orderId,
            money: props.isOrder ? state.box.pay_price : state.box.pay_price * state.addnum,
            payStatus: state.payStatu,
            score: state.cheacked ? state.piontDeduction : 0
          }
        });
      } else if (state.payType == 25) {
        // 杉德支付
        // console.log(1111);
        const { data, status, message } = await sandpay_unionpayh5({
          order_no: state.orderId
        });
        payCallBack(data, status, message);
      }
    };

    // 支付回调
    const payCallBack = (data, status, message) => {
      setTimeout(() => {
        state.getCard = true;
      }, 1500);
      Toast.clear();
      if (status == 0) {
        state.payStatus == 1 && localStorageUtil.set('orderId', state.orderId);
        if ([18, 42].includes(props.box.collections_id) || [18, 42].includes(props.box.id)) {
          // 新人盲盒
          localStorageUtil.set('isNew', 1);
        }

        //预付款
        // if (state.payStatus == 2) {
        //   let allpays = localStorageUtil.get('collections_id')
        //     ? JSON.parse(localStorageUtil.get('collections_id'))
        //     : [];
        //   allpays.push({ id: state.box.id, order: data.orderId });
        //   localStorageUtil.set('collections_id', JSON.stringify(allpays));
        // }
        state.payStatus == 2 && localStorageUtil.set('refresh', 1);
        location.href = data.url;
      } else if (status == 401) {
        // 实名认证
        Toast(message);
        setTimeout(() => {
          router.push({ path: `/authentication` });
        }, 1000);
      } else if (status == 409) {
        // 有未支付订单
        Toast(message);
        setTimeout(() => {
          router.push({ path: `/order` });
        }, 1000);
      } else {
        Toast(message);
      }
    };

    return {
      ...toRefs(state),
      close,
      toPay,
      payMeth,
      getCardMeth,
      sureCoup,
      orderPay,
      justPay,
      payCallBack,
      piontComputed,
      arrowClick,
      stepChange,
      toinvite,
      icon_tc_gb: require('../imgs/icon/icon_tc_gb.png'),
      icon_point: require('../imgs/icon/point.png'),
      coll_no_choose: require('../imgs/icon/coll_no_choose.png'),
      coll_choose: require('../imgs/icon/coll_choose.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.maxnum {
  font-size: 10px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}
.can_buy_num {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  .getmore {
    color: $primary;
  }
  span {
    color: $primary;
  }
}
.addnum {
  margin-top: 12px;
}
.line {
  width: 100vw;
  height: 1px;
  background: #494359;
  // margin-right: -9px;
  position: absolute;
  top: 0;
  left: -20px;
}
.pay-bt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 15px 0 24px;
  position: relative;
  .pay-money {
    .span {
      font-size: 12px;
      text-decoration: line-through;
      color: rgba(255, 255, 255, 0.7);
    }
    .pay {
      color: #3cdbe8;
      font-weight: 500;
    }
  }
  .btn {
    width: 136px;
    height: 36px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    color: #1c172a;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.point {
  background: linear-gradient(146deg, #4a46ff 0%, #8348ff 100%);
  padding: 9px;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  .point-left {
    display: flex;
    align-items: center;
    .imgbox {
      padding: 2px 3px 0;
      border-radius: 50%;
      background: #1d0070;
      border: 1px solid #fff;
      margin-right: 3px;
    }
    img {
      width: 14px;
      height: 14px;
    }
    .words {
      font-size: 12px;
      .col {
        color: #3cdbe8;
      }
      .ft {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  .choose {
    font-size: 22px;
    display: flex;
    align-items: center;
  }
  .arrow {
    font-size: 16px;
  }
}
.pay-box {
  display: flex;
  margin-bottom: 25px;
  height: 80px;
  .payitem {
    text-align: center;
    width: 64px;
    padding: 11px 0 9px;
    background: #494359;
    border-radius: 4px 4px 4px 4px;
    margin-right: 14px;
    border: 2px solid $bc;
    img {
      width: 32px;
      height: 32px;
    }
  }
  .active {
    border: 2px solid #3cdbe8;
  }
}
.stepper {
  --van-stepper-background-color: #494359;
  --van-stepper-button-disabled-color: #494359;
  --van-stepper-button-icon-color: #fff;
  --van-stepper-input-text-color: #fff;
  --van-stepper-input-disabled-background-color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  // height: 26px;
  overflow: hidden;
  font-size: 0px;
}
.content {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-left {
      display: flex;
      img {
        width: 56px;
        height: 56px;
        margin-right: 8px;
      }
      .display_name {
        font-size: 18px;
        font-weight: 500;
      }
      .pay_title {
        font-size: 12px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 6px;
      }
    }
    .title-right {
      span {
        font-weight: 600;
        color: #3cdbe8;
        font-size: 24px;
      }
      .icon {
        font-size: 12px;
      }
    }
  }
  .num-box {
    margin: 15px 0 27px;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;
    align-items: center;
    .num-left {
      display: flex;
      .choosenum {
        width: 64px;
        height: 36px;
        background: #494359;
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;
        position: relative;
        border: 2px solid $bc;
        .tags {
          position: absolute;
          top: -12px;
          right: -15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10px;
          max-width: 62px;
          padding: 1px 4px;
          box-sizing: border-box;
          border-radius: 19px 19px 19px 19px;
          background: #ff8616;
        }
      }
      .active {
        border: 2px solid #3cdbe8;
      }
    }
  }
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  background-color: $bc;
  padding-top: 3px;
  .img-zs {
    height: 16px;
  }
  .icon_tc_gb {
    margin-left: 300px;
    width: 16px;
    height: 16px;
  }
  .img-title {
    height: 16px;
  }
  .type {
    // line-height: 16px;
    font-size: 18px;
  }
  span {
    font-size: 20px;
    color: $fc;
    margin-top: -20px;
  }
}
.block {
  height: 60px;
  background-color: $bc;
}
.block2 {
  height: 28px;
  background-color: $bc;
}
.content {
  background-color: $bc;
  color: $fc;
  padding: 0 20px;
  font-size: 14px;
}
</style>
<style>
.addnum .van-stepper__input {
  margin: 0px;
  background: #1c172a;
}
</style>
